import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Mic }  from 'react-feather'


const Unsplash = () => (
  <Layout>
    <SEO title="Founder Immigration Guide" />


    <div id="top-part">
      <div id="left-col">
        <div id="left-col-content">
          {/* <div className="page-icon-container">
            <Mic className="page-icon" />
          </div> */}
          <h1 id="community-header">Michael Cho, Founder and CEO of Unsplash</h1>
          <h2 className="description"></h2>

        </div>
      </div>
      <div id="right-col">
      

      
    
      </div>
    </div>
    <div className="interview-body">

<article>

<p class="intro">Mikael Cho is the co-founder and CEO of Unsplash. He moved to Canada from the US in 2008, and grew up in Wisconsin. He was previously the co-founder of Crew, which was acquired by Dribbble in 2016. Unsplash is based in Montreal, Quebec.</p>

<figure>
<img src="images/mikael.jpg" alt="The Unsplash Team" id="wide"/ >
<figcaption></figcaption>
</figure>


<p>I moved from the U.S. to Montreal in 2008 for my now wife and co-founder Steph Liverani on a one way bus ticket and just stayed. At first I wasn't necessarily looking to start starting a company, I was just looking for employment. Which I then realized was kind of difficult to do in Quebec considering I didn't speak French. Luckily I did find some employment early on, like call center sales.</p>

<p>After that I started working at an agency where I learned and fell in love with what's possible on the internet, and saw things that could be solved in that world first-hand.</p>

<h2>Starting Up</h2>
<p>That led me to Unsplash, which began as an idea that wasn't really intended to be a company. It was built into another company that had started to solve things that I saw as issues in the agency world, Crew. The idea of which was to connect high quality engineers and designers with high quality contract projects, and then try to automate the sales, and product management, and everything else.</p>

<p>We knew that would be a monumental task to build, so we tried to consider how we could get people hanging around our front porch before we built all of that by solving little problems that they had. Unsplash was initially an idea to solve one of those little problems. I'd moved to Montreal in 2008 and started Crew and Unsplash in 2013.</p>

<h2>Lean Traction</h2>

<p>We were very excited about Crew. It was growing really well, and then Unsplash was like an additional rocket ship that we attached to it from day one. I put it on Hacker News on that first day, and we made the site in three hours. It was a Tumblr blog with public Dropbox links. That's how little we thought people would see it. With Dropbox links, it’s not supposed to scale past maybe a few hundred views, and we were getting tens of thousands of views on each of those links, on that first day.</p>

<p>Right away we kind of knew there was something that was happening there, but we were still very excited with Crew so we just let Unsplash be a source that was useful for the customers that we were talking to. Then over time, when people started contributing to it, it started growing way more, it was something like 32x in that first year. It was insane, and we had to think about making sure that we were not clipping its wings, because I've never seen experienced anything like this. That's when we moved one person full time on it.</p>

<p>So we knew there was something there, but we progressively, dedicated more and more to it, as it looked more and more like its own business.</p>

<figure>
<img src="images/unsplash-office.jpg" alt="The Unsplash Team" id="wide" />
<figcaption>The Unsplash offices.</figcaption>
</figure>

<p>The Pivot</p>

<p>This was really difficult. We had a one person full time on Unsplash, my co-founder Luke Chesser, and everybody else was working on Crew. Unsplash continued to grow. Unsplash was consistently the number one referral source for customers for Crew. Within another year or two years from there, it started being used by people completely outside of the customer base for Crew. That was one of the first inflection points where we were thinking that we should probably spin it out into its own.</p>

<p>We had raised $10 million money for Crew at that point. We considered the trajectory of both businesses. Crew was growing well, but it was still...it takes a lot of time to get that right. We looked at the best scenario for each business, and the kind of business that it is. For Crew, we thought the best thing was not raising more money. It was almost getting to profitability. So we switched our focus to that and considered making it a strong business with potentially another partner through acquisition.</p>

<p>That would allow us to keep the whole Crew team on. We still had some funds left, and Unsplash wasn't monetized yet. It was growing really well, but it needed some money to actually to hit some of the next milestones. So we decided to allocate the remaining capital we had to it. Which was around 2016. I might be off by a year.</p>

<h2>Dribbble Acquisition</h2>

<p>In the beginning of 2017 Crew was sold to Dribbble. We managed to keep the whole team together there. Then I went off with a couple of other team members who were on Crew to Unsplash full-time. We had about a year's runway for Unsplash specifically. Then after that we raised a $10m round for Unsplash.</p>

<figure>
<img src="images/unsplash-team-1.jpg" alt="The Unsplash Team" id="wide" />
<figcaption>The Unsplash team.</figcaption>
</figure>

<p>It was weird. I think the mindset shift is the weirdest thing, because I never build anything for it to be sold or acquired, and but rather to make something great and useful. When you shift your mind into that, it's like, oh yeah, that's a little weird. Then I think going through the process, we had some unique things, because we had Unsplash at the same time, so it's like we've got this other thing that we're trying to help grow up. It's like, we're trying to help it stand, but then we've also got ... figure out how to do an acquisition at the same time. It's weird, because both the companies were very young, and so doing both of those at the same time, did feel weird.</p>

<h2>Raising Money</h2>

<p>Raising money for Unsplash was difficult due to a few factors, the coming out of Crew business, it's a spin out, it's this Canadian thing, it's not making money yet. You had Instagram, and photo businesses, and what's the future of that, and you just kind of get looped into these things, but I also think there's some introspection that's valuable there too, like what could I have done better, what could we have done better to present Unsplash and what it is. Where we are now, I think the way we speak about Unsplash and the clarity we have around it is different than what we had at that time. I think it's better now.</p>

<p>I feel like there's things that I could have said better and presented better when we were fundraising for Unsplash. I think a lot, there are external things that can happen, but I think it's important to look internally, and think first about what could you have done better. I always look at that like storytelling is probably the number one thing that you could improve, like how does this fit into the world today, and in the future, and being able to communicate that really well.</p>

<p>You see all this stuff about how to do your pitch deck well and how you should structure it, and all this stuff. To me that's like the SEO of pitch deck prep. It's going to get you maybe 10%, 20%, better improvement, but the meat of what you're saying I think largely comes from the confidence that you have around what you see, and the story that you're telling. The way you build that confidence is you actually have something you've communicated regularly and done a lot of that groundwork to feel confident telling that story.</p>

<p>To me that's the number one thing. I always do this exercise before we go and fundraise. If you yourself wouldn't take let say 30% of the money that you have to your name, and you wouldn't put that all into your company, then you're probably already not confident enough. There's something missing in your pitch. There's something missing in your story.</p>

<h2>Confidence</h2>

<p>I think I've always tried to make things happen. I haven't felt like we didn't have something. I think I've always actually been on the other side, where I feel we have more than maybe ... Some of that is the ... I forget the term for it, but you know, the maker who is close to their thing, they tend to value it more than the outside sees it. I try to actually go the other way and keep it even keel, and know okay, what else is happening in the market, like what is everybody seeing, because everything is relative.</p>

<p>They've seen all these things, so by nature you're going to compare it to something that they've seen. Knowing that I think is helpful in figuring out where you sit in that, but I think it's still, I see a lot of values that we have and I think it tends to be higher maybe than what the market perceives, but that's the optimist and the maker connect to the product more so than anything I think.</p>

<h2>Building a Community</h2>

<p>It started with us. I think part of this is part of how a community gets built. You resonate with other people. It's all about people. No matter how good a bar is, it's not cool unless the people there are cool. You want to be useful, and I think that's just what we've always tried to do. Then other people who resonated with that, they joined in as well. It started with our own images. We said, "This is the community that we want to exist, and we'll be the first ones to participate in it." I think that right away is a huge thing.</p>

<p>If you're not participating in your own community, you'd find it very difficult to maintain the authenticity necessary to build a great one, or a group of people around anything that you're trying to do. For us, it was having the problem, attempting to solve it for us and other people, and then I think other people just resonated with that. Then the rest just comes so natural, because you're already trying to all solve these problems together. You resonate with the problem space. You're all trying to figure out ways to do this, and I think it's that core resonation with the problem that really starts into building a strong community.</p>
<p>I focus there. Then it's just kind of like doing natural people stuff, like what you do with your friends. You like them, so you just talk to them. I think making that a priority is a big piece of what we do, and what we really care about. We're all over talking to a lot of our community members all the time. People might say, "That's the thing that doesn't scale," but the community for us, if Unsplash doesn't have the great imagery in that, it's just a whole bunch of white boxes and windows. It's not really that useful.</p>

<p>We don't force our team to contribute to the larger community. You could make it a rule, everybody should be contributing, but we never really had to do that. I think some of it is that there's a natural pull, and then a lot of the people who either we found who wanted to join us full time, later on they would start publishing. Some of them maybe weren't publishing in the beginning, but yeah, it's great to see that everybody on the team uses the product. I think that's core to being connected to big community.</p>

<figure>
<img src="images/unsplash-team-2.jpg" alt="The Unsplash Team" id="wide" />
<figcaption>Another photo of the team.</figcaption>
</figure>
<h2>Biggest Challenges</h2>

<p>The first major challenge was identifying really what it means to have product market fit. I think there's times when you think you have it. The first time I realized what product market fit really is was that first day of Unsplash. To see people using the site, how many people, how often, coming back to the site, were doing 10 images every 10 days. People would download all the images, and store them in a folder to make sure they didn't disappear. It was like a phenomenon of behavior that I hadn't really seen.</p>

<p>Then there was this moment where I realized, even if we stopped doing anything, like if the whole team stopped doing something for a week, probably wouldn't even notice. It's just kind of taken over by the community, people who contribute, and use the site. When you feel that pull, knowing that that's what product market fit looks like, I think is a very useful benchmark to know, with Crew, we knew we weren't quite there at certain phases, but I think we hit into product market fit. Then there was another layer that we were building, so we kind of moved out of product market fit in that section of the product.</p>

<p>We would get there eventually, but it was interesting to feel, think what you should be looking for when you do have product market fit. That identifier, I found that that was really useful, and as challenges, the challenge to overcome. Getting to that is difficult. I would say the second piece is, you should try to get there by solving your own problems. It's just a lot easier to do. I find it probably very ... I don't know if I could ever start something where it wasn't the problem that I had, because you won't be able to go deep enough. You won't be able to probably go fast enough.</p>

<p>We could depend ... The first version of Unsplash wasn't built with any AB testing, or talking to anyone. We just had the problem ourselves, and we saw it, that this might be the ideal solution for the problem we had, and it just turned out that millions of other people had the same problem. Those are the top two. The third is, reeling yourself back in, even after you've had maybe some successes. I feel we could have been more focused, like myself. I was just seeing the opportunity. Crew is growing really fast, and you start to do things faster, maybe than you should, so we grew the team. You know, that time from after we had raised funding for Crew, we went from like 10 to 30 in a span of four months. I would have slowed that down in hindsight, and done it slightly differently.</p>

<p>It's not about new office, and different marketing things, and going after different marketing channels, and there was just a lot, and I think a lot at once. I've really subscribed more to this sequencing mentality of mastering something at a certain level, having the team and the people in place who can scale that, letting them do that for a bit longer than ... You kind of feel you want to like, okay, let's go tackle another challenge. I'm kind of ready to ... I think we can move over here. I've kind of added probably another buffer to that. Let's say after two months I think we're ready, I'll add another month onto that first part and just be like, let's give this a bit more time to make sure we didn't miss anything here. Then we can move on to the next part. I would say those three things to me are the things I learned the most.</p>

<h2>Focus</h2>
<p>I would say, I actually think, not like a specific product feature, but there is new ideas that we've thought about, that we just didn't do. A lot of people think, and we've even thought of it, hey, we should do content marketing. We could write, have a blog, and teach photography, and we could do a lot of things there about creativity, and inspiration, and we just have not really thought that that's a bigger priority than other things that we have going on.</p>

<p>It seems like you could get 10 blog posts up a month, and start getting a bunch of SEO, but from experience, we had a lot of content with Crew, I know what goes into that. We're a team of 20, and we're intentional about each hire we make, and where that's going within the company, and we were willing to say, "No," to these opportunities so that we can focus on basically it's three main areas of the company that we know are the thing that helps us accelerate. We really just try to push more down into those three areas, versus adding something else.</p>

<p>If we consider adding something else, we'll do these really small tests if we think so, but a lot of it is cut off before we even are testing something, because we either have some of that experience before, and those are ones where we try to cut it before we're wasting time down a path, is what we see it.</p>

<h2>Leadership</h2>

<p>It matters a lot. It's just everything, even the people who depend on your product. I look at it like it's a very big responsibility. I'm of the nature of just not ... I don't really give up easy. I fight very, very hard. I've never given up on something that I believe. That's just the mentality that we have in the company, and I have that for myself, I have that for my teammates, and I have that for everybody who is using the product and supporting what's going on at Unsplash. Yeah, something that matters and I think about, and I'm related to it too.</p>

<p>It's kind of like, we're on a boat and I'm one of the people on the ship too. I don't think of it like I'm off, you know, I'm somewhere else, and everyone else is on this ship. I'm really feeling part of it, and that's how I think the whole team operates in that way as well.</p>

<p>Our view of the world is that everyone should be enabled to create. If we can make that easier, we can make that more possible by helping provide imagery, different things, different fundamental building blocks to enable them to create, to us that's a very big and important thing to do. If we look at most problems in the world, most of them are solved by human creativity, and ingenuity. If we can enable that, I think that helps the world. That's what we want to be doing. For us to be even more specific, we want to keep the site open, we want to keep it accessible, so everything we fit into what we build, has that future lens in place.</p>

<h2>Handling Feedback</h2>

<p>Yeah, I think early on it was a bit ... I hadn't met many people. I didn't know, let's call it some of the top people in the tech world. I hadn't met them previously, and that was the first time meeting them, speaking about Unsplash. When some of them have strong opinions, you're thinking it twice a bit more. What I realized after more and more conversations, we actually ... I would be with my co-founders, and the team, and we'd have our own conversations. I think it's nice to have that little friction sometimes, about the beliefs that you have. Then you can go deeper. You can go levels deeper, and question yourself, question, understand.</p>

<p>Often, we were actually, through those exercises, we were coming out even stronger. The more meetings we had with the more intellectual people we had, we would still sometimes take those thoughts, but then eventually it was like, oh, this is going to be the right thing. This is the right thing. We've analyzed it, we've thought about it from a hundred different angles, we've gotten people shooting holes at our thesis, and we keep coming out in this place, after conversations where we are being unbiased, very level headed, we're bringing other people in.</p>

<p>I tend to be kind of searching for truth all the time. I'm not necessarily trying to keep any bias, very difficult, but I try my best to make sure that we're building towards a truce. I felt that more and more actually through this process, even though there were some people who would come in, who had track records, that would suggest all their opinions could be great. I think you have to realize, not all the time, and sometimes that ... You don't know everything about that track record and other things, so you yourself, just having that, again, it kind of comes back to the confidence of where does that come from. I think a lot of it comes from actually going through some of this stuff.</p>

<p>I'm actually very happy we went through some times where people then believe, and we spoke a lot with those people to get to where we are now, which has actually helped build us to the feelings, and the position that we have now.</p>

<p>Yeah, I found it like ... Every company is essentially a research paper, and you should be confident ... If you could write out that research paper when you go and speak, you are going to speak very well, and clearly, about what you're doing, and you're going to understand why you're doing it at a deep core, ground truce kind of level.</p>

<h2>Startups in Canada</h2>

<p>Yeah. I mean, I'm grateful for it. When we started back in, it would have been 2012, was when we received the first bit of funding, it's when Real Ventures was just starting with FounderFuel. I think they're the most active seat investor in all of Canada. I think that really helps. It propels that beginning part, like more people felt comfortable starting something. They've really enabled that. I still think there's work to do in the later stage. I've found once we get kind of towards series A and beyond, we're finding ourselves just going to the U.S.</p>

<p>I think there is some time elements. We've heard how to have networks in different cities, and how does that happen, especially in tech, you need a couple of big exits. Then it sort of circulates back through the ecosystem, learnings, people, funding. I don't think that's happened yet enough, on that post, like series A, and series B, but I could see it starting to happen, because of the pump that's kind of happened at the beginning of the market, so people like Real Ventures, and what they've done with FounderFuel.</p>
<p>Without that, I don't know, I didn't really know how to get into tech or start ups. That was really big, and that's been a recent thing.</p>

<p>Yeah. From what I hear, read, we've got great people. I didn't hear and read that, but I'm just saying another thing. We've got great people. We've got people who are starting to recognize that there's great people here. We've got places like Waterloo, McGill, these are great schools too, and there's great companies. There's companies who have engineering talent, there's a history of that, there's a history of art, especially in Montreal. When those things merge you tend to get good things. I think the hot bed is kind of there, for more and more great things to start happening.</p>

<p>I think there's layers being built on that hot bed. Yeah, I would say it's definitely turning in the right direction, like you're connected as a country to the tech capital of the world. There's definitely interesting dynamics for Canada specifically.</p>

<h2>Crew Cafe</h2>

<p>Yeah. In hind sight I wouldn't do it again, but at the time, it was really connected to Crew again. Our philosophy around helping people learn about Crew, and getting awareness for Crew, and building into the market, was to be useful. Again, that same philosophy, how can we solve other problems for our community. I would have sequenced it differently. There's not like ... Maybe we never would have done the Crew Café. I think maybe we would have done it later, but I probably wouldn't have done it at that time. I think we just sequenced it improperly.</p>

<p>It was meant to be ... We didn't have an office. We were a distributed team, and we were about to get our first office in Montreal, and I thought, how could we make this like ... If Crew existed in the offline world, what would that look like. Our team was part of the mix. We had great freelancers, we had people who were looking to make websites, mobile apps, and I had always been going into these nice tech offices that are way up in the sky, nobody has access, so I thought, well what if we could just bring that to the first floor, and then we would make that our office. It could be a nice place for people to come, and interact, and [they're 00:35:10] probably be our customer base.</p>

<p>It may even pay the rent. It would be a nice unique marketing tool, if it could rank highly as the number one café, and be sort of press worthy, so it'd be great for branding, and everybody's sort of coming in, and they're logging onto the Wifi, and that's sort of telling people about Crew. Yeah, that was the concept, and it did ... That all happened. It's just it's kind of ratcheting on almost a whole other business, even thought you might just say it's a café, but it's still a difficult thing to set up, especially the grandioseness of it.</p>

<p>We went after a space that was basically the size of an Apple store, 50 foot ceilings, it was the original Royal Bank of Canada headquarters. It sat on the first floor. We turned it into a very big space that could handle probably the most traffic in the city, in terms of a café. Yeah, it ended up being that. It was a great experience, but were it related to the business, I would have done it much later, if at all.</p>


<h2>Distributed Teams</h2>

<p>We're mostly working distributed and remote, so I actually didn't think located was that big of a deal. I still don't. I think we are moving into this world where a lot of things are being solved really well with software and tools, although there are still some things that could be improved. Even though we have an office, most of our team is distributed and we operate in a distributed way. We're distributed first, and there are little things we do that have come out of that. We don't hold any meetings where there's multiple people sitting in a meeting room, and there's other people who are calling in on distributed phones or on their laptops.</p>

<p>Which would create sort of a disconnect, like there's people in the room who may get extra information because they're there, versus people who are remote, so we actually have these little booths, and everybody goes into the booths, and we do all these calls, so everyone is in the same context, in the same setting. Yeah, that's kind of how I feel about location. There's people who feel differently. Obviously we end up having to go to New York, and San Francisco every now and then but we have to be more intentional about it.</p>

<p>When working with distributed teams defaulting to written communication is crucial. We try not to have offline conversations as much as possible. Even though two people might be next to each other, we still communicate through a digital tool, so that everybody on the team gets that context. I think that would be probably one of the biggest things that we make sure to do. We try to do video as much as possible. When there is a call scheduled, we try to do video versus people turning their videos off, just to have some of that connection, because I feel it. If I work at home for multiple days and there's no people, I start to want to see people. I just freak out a little bit, and I'm pretty good at working alone.</p>


<p>We've solved I think most of the other potential issues of being in Montreal, and not in like New York, or San Francisco, where rents are rather expensive by being a distributed first company.</p>


<h2>Living in Montreal</h2>

<p>I really like it. I think sometimes you feel maybe like not everybody's here, that you want to be interacting and meeting with, that could maybe help move things or accelerate things faster, but I think you can just be intentional about going to those places. It depends on the business that you're building too. We don't necessarily need to be in New York right now. We don't need to be in San Francisco for the kind of business that we're building. We've tested some of this. We've gone and spent uninterrupted time there, booked a flight there with no return, and we tested that, versus how effective would we be from a distributed perspective. We almost saw no difference.</p>

<p>Now there may be a compounding factor that we're not controlling for, so if you end up staying there for longer periods, like three, six, nine months a year, the serendipity is very hard to replicate. I still do believe that the best city that exists is on the internet, and you can find people, and as long as you take that time, you just have to be more intentional about it than relying on serendipity, I think you can get still a lot of the benefits that you would, if you were in like San Francisco, or New York.</p>

<p>Yeah. I think people who enjoy building things, I don't find there's a lot of distraction here. I find that a bit more when I'm in probably specifically San Francisco, there feels like there's more things that you could go to, there's more meetings, people that you could always be connecting with, but when you're really in a build mode, and you're focused on, or you enjoy just building things, I find Montreal is this really good spot for that, just less people. The U.S., it's easier to go down and just grab some food, there's just no lines, you don't have to make reservations, especially if you're one or two people. I think one, the quality of life ranking from ... I don't know if it was ... I think it was the Tik Tok, whoever runs that. I forget who it is. Was it Bloomberg who runs that?</p>

<p>It was the number one this year for people feeling happy and content. That's the kind of vibe that it is. I think when you get on the digital world, it can get really intense. That's one thing that I like being about Montreal, we can step out, and we can go on the street, we don't see ads for tech companies. We see other things, so there's different influences. The culture is more of a European culture too, so people kind of feel connected to other things in life. I like that balance, because when I go on a screen, I'm in the mode of building a great business, and we'll do that for long stretches, long periods of time. It's kind of nice when you detach from that screen, not to also have that everywhere in your real world.</p>

<p>Yeah. You get influences from other places. Montreal has great art, great music, great food, people who work in those industries as well. I think it makes your brain more eclectic as well also.</p>

<p>Like probably most cities, summer is amazing. We even try not to go anywhere any ... It's so good, that we try not to go anywhere else in the summer, because we want to spend the whole summer here. Be prepared for a couple months in the winter, where it's pretty cold, but again, it's kind of a nice filter. If you really enjoy building things, the winter here helps with that. You just kind of want to keep building all the time, because there's not much going on outside. You don't really want to go outside, [because it sucks 00:44:56].</p>

<h2>Canadian Government Grants and Services</h2>

<p>I don't deal with it directly, some members of our team do. I don't know like the full amounts, and what happens, and what gets covered, but we do some work with the government for government tax credits. Then we have also worked with getting people visas, and helping them get to Montreal. Specifically we've had ... I think it's ... We've had engineers mostly that we've been able to help that with, and there was a couple ... I think there was a business development person that we helped with that as well.</p>

<p>Yeah. I don't have experience building in the U.S., a company, so I can't speak directly to it, but from what I've heard, yes. I've heard that there's a lot more support up here than it comes from the U.S. We’re very happy with it.</p>

<p>To me, the big focus is always on the people, how can we connect more of the great minds and the great people in the world, to Canada, because that's interesting. I just kind of depend on the internet as my serendipity, but if you could create some more serendipity up here. There's certain things that are going on that try to do that. We have International Startup Festival here in Montreal. There's different start up events I think also in Toronto, and Vancouver, that happen, and that helps bring people up. Collision Conference was just here like a month ago. That was in Toronto, and that brought a whole bunch of people up to Canada. I think those things are really, really good, and hopefully ...</p>

<p>When we started fundraising in like 2012, 2013, I think there's lots of variables you could say for why people say, "No," to you. I think there was, especially at that time, there was like, oh, you're like this little Canadian thing, not going to listen, I'm going to kind of shut off my ears. That could be because I sucked at pitching. I felt that there was a bit more of that than when I felt different things, like years later. I feel like there's been an opening up towards Canada. There's something interesting going up there. That's kind of like the quotable.</p>

<p>I love hockey. Crazy. I guess now the Raptors. Yeah, I think that, but I'm from Wisconsin, so Wisconsin's kind of like America's Canada. I felt more similarities than differences. I would say just, yeah, some of the little things are Canadian money, obviously in Montreal and Quebec, it's speaking French. I didn't even know that that was so prominent here. The people are fantastic.</p>



<figure>
<img src="images/unsplash-retreat.jpg" alt="The Unsplash Team retreat" id="wide" />
<figcaption>Unsplash team retreat.</figcaption>
</figure>

<p>Yeah, I think you could ... If you made the money the same, I think you could take someone from the U.S., who was sleeping, and drop them into Montreal, or ... maybe not Montreal, or Quebec, because they're French, but anywhere else in Canada, like Toronto, and they'd probably feel like they were in the U.S. The French thing was pretty tough for me, but that's a unique Quebec thing.</p>
</article>
    </div>
  </Layout>
)

export default Unsplash
